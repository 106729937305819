<template>
  <component :is="commonComponent" id="terms" title="Terms of services">
    <section id="terms-content" class="pc_padding">
      <div class="common-content-head">
        Welcome to the TimeCheckNow
      </div>
      <div class="common-content-row">
        Welcome to the TimeCheckNow website (hereinafter referred to as "the Website"). Please read
        the following terms of service ("Terms") carefully to ensure that you understand and agree
        to them. By accessing or using the Website, you agree to be bound by these Terms. If you do
        not agree to these Terms, please do not use the Website.
      </div>
      <div class="common-content-head">
        <span>1</span> Acceptance of Terms
      </div>
      <div class="common-content-row">
        By accessing and using TimeCheckNow.com, you agree to adhere to these User Terms and
        Conditions. If you disagree with any part of these terms, please discontinue use of our
        website immediately.
      </div>

      <div class="common-content-head">
        <span>2</span> Permitted Use
      </div>
      <div class="common-content-row">
        You are granted a limited, non-transferable license to access and use TimeCheckNow.com for
        personal, non-commercial purposes. Any other use of the site requires prior written consent
        from TimeCheckNow.com.
      </div>

      <div class="common-content-head">
        <span>3</span> Ownership and Intellectual Property
      </div>
      <div class="common-content-row">
        All content, including text, graphics, logos, images, and software, is the property of
        TimeCheckNow.com and is protected by copyright, trademark, and other intellectual property
        laws. Unauthorized use of any content is strictly prohibited.
      </div>

      <div class="common-content-head">
        <span>4</span> User Responsibilities
      </div>
      <div class="common-content-row">
        You agree to use TimeCheckNow.com responsibly and in compliance with all applicable laws.
        You must not engage in any activity that could harm the website, its users, or its content.
      </div>

      <div class="common-content-head">
        <span>5</span> No Warranty
      </div>
      <div class="common-content-row">
        TimeCheckNow.com is provided "as is" without any guarantees or warranties of any kind. We do
        not guarantee that the site will be available at all times, or that it will be free from
        errors or interruptions.
      </div>

      <div class="common-content-head">
        <span>6</span> Limitation of Liability
      </div>
      <div class="common-content-row">
        TimeCheckNow.com shall not be liable for any damages arising from the use or inability to
        use the site, including but not limited to direct, indirect, incidental, punitive, and
        consequential damages.
      </div>

      <div class="common-content-head">
        <span>7</span> Indemnity
      </div>
      <div class="common-content-row">
        You agree to indemnify and hold TimeCheckNow.com harmless from any claims, losses,
        liabilities, damages, and expenses, including legal fees, arising out of your use of the
        site or violation of these terms.
      </div>

      <div class="common-content-head">
        <span>8</span> Modification of Terms
      </div>
      <div class="common-content-row">
        TimeCheckNow.com reserves the right to change these terms at any time. It is your
        responsibility to review these terms regularly. Continued use of the site after any changes
        constitutes your acceptance of the new terms.
      </div>

      <div class="common-content-head">
        <span>9</span> Termination
      </div>
      <div class="common-content-row">
        TimeCheckNow.com may terminate or suspend your access to the site without notice for any
        conduct that it believes violates these terms or is otherwise harmful to the site or its
        users.
      </div>

      <div class="common-content-head">
        <span>10</span> Governing Law
      </div>
      <div class="common-content-row">
        These terms are governed by and construed in accordance with the laws applicable to
        TimeCheckNow.com, without regard to conflict of law principles.
      </div>

      <div class="common-content-head">
        <span>11</span> Contact Us
      </div>
      <div class="common-content-row">
        For any questions or concerns regarding these terms, please reach out to us through our
        contact page.
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'Terms of services',
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>
